import React from "react";
import { useLottie } from "lottie-react";
import { Animation, Logo } from "./assets";
import "./index.css";

function App() {
  const options: any = {
    animationData: Animation,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <div className="main">
      <img src={Logo} className="logo" alt="logo" />
      {View}
    </div>
  );
}

export default App;
